import TeamCard from "../Helpers/Cards/TeamCard";
import DataIteration from "../Helpers/DataIteration";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

import axios from "axios";
import { useEffect, useState } from "react";
import * as Constants from "../../Constants/index.js";

export default function Teams() {
  const [teamsData, setTeamsData] = useState([]);
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const resp = await axios.get(Constants.api + "teams");
        if (resp.data.success) {
          setTeamsData(resp.data.teams);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchTeams();
  }, []);

  console.log(teamsData);

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="blogs-wrapper w-full-width">
        <div className="title-bar">
          <PageTitle
            title="Our Teams"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "teams", path: "/teams" },
            ]}
          />
        </div>
      </div>

      <div className="w-full py-[60px]">
        <div className="container-x mx-auto">
          <div className="w-full">
            <div className="grid md:grid-cols-4 grid-cols-1 lg:gap-[30px] gap-5">
              <DataIteration
                datas={teamsData}
                startLength={0}
                endLength={teamsData.length}
              >
                {({ datas }) => (
                  <div
                    data-aos="fade-up"
                    key={datas.id}
                    className="item w-full"
                  >
                    <TeamCard datas={datas} />
                  </div>
                )}
              </DataIteration>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
