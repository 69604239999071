import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function PrivacyPolicy() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Privacy Policy", path: "privacy-policy" },
            ]}
            title="Privacy Policy"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                1. Definitions
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">
                “Account” shall refer to the user-generated account hosted on the Platform and created subject to the submission of Personal Information and Sensitive Personal Information by the user.
                “Act” refers to the Information Technology Act, 2000, as amended from time to time.
                “Applicable Laws” means any applicable statute, regulation, ordinance, rule, judgment, etc.
                “Personal Information” means any information that relates to a natural person and is capable of identifying such person, such as name, email, physical address, and phone numbers.
                “Non-Personal Information” includes geographic location, telecom details, browser type, etc.
                “Sensitive Personal Information” includes password(s), financial details, PAN, etc.
                “Platform” or **Ezybuying.in** refers to the e-commerce website owned and hosted by Fortune Trading House.
                Any capitalized words not defined herein shall have the same meaning ascribed to them in the Terms of Use.
              </p>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                2. Children Under the Age of 18
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 mb-10 text-justify">
                The services provided by Us are not intended for children below 18 years. We do not knowingly collect Personal Information from children under 18. If it comes to our knowledge that we have collected information from a child under 18 without parental consent, we will delete that information.
              </p>

              <h2 className="text-[18px] font-medium text-qblack mb-5">
                3. Objective for Collection of Information
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 mb-10 text-justify">
                We collect Personal Information to enhance the Platform experience, fulfill user requests, provide updates, and personalize interactions. This includes:
              </p>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Enhancing user experience on the Platform.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Responding to inquiries or fulfilling requests for information.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Providing product information and promotional offers.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Updating users with important information, policy changes, etc.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Sending surveys and marketing communications.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Personalizing advertisements and product recommendations.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Analyzing service usage and measuring ad effectiveness.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Permitting third parties to provide support services.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Determining security breaches and investigating illegal activities.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Resolving disputes, if any.
                </li>
              </ul>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                4. Controller of Personal Information and Sensitive Personal Information
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                Any Personal Information or Sensitive Personal Information provided to or gathered by Us under this Privacy Policy will be stored and controlled by **Ezybuying.in**.
              </p>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                5. Controller of Non-Personal Information
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">
                Any Non-Personal Information provided to or gathered by Us under this Privacy Policy will be stored, owned, and controlled by Us.
                <br />
                <br />
                **Ezybuying.in** reserves the right to use the Non-Personal Information updated by the Users on the Platform and/or generated during the course of usage of the Platform in any manner which it deems fit and in compliance with the Applicable Laws.
              </p>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                6. Information Accessibility
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">
                Please note that You may visit the Platform and access any information or data or part of the Platform which we authorize access to without having to provide any Personal Information or Sensitive Personal Information. However, when You set up an Account on Our Platform, we collect and store your Personal Information or Sensitive Personal Information which is provided by you from time to time. Our reason for the collection of such information is to enable You to have a hassle-free, efficient, safe, and easy user experience. We may use Your Personal Information or Sensitive Personal Information to assist sellers in handling orders, assist Our logistics service providers to deliver products, process payments, communicate with You about orders, products, promotional offers, display customer reviews, or recommend products that might be of interest to you or for other purposes mentioned in this Privacy Policy. The kinds of information We gather from You are listed below:
              </p>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                7. Account Information
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                We may collect Your Personal Information such as email address, name, phone number, etc., and Sensitive Personal Information provided by You to fulfill the Know Your Customer (“KYC”) requirements, etc. You may also add other information to Your Account, such as a profile name, profile picture, and status message.
              </p>
              <br />
              <p className="text-[15px] text-qgraytwo leading-7">
                If You use the chat facility available on the Platform, We will collect the communication exchanged between You and other users of the Platform, which may pertain to Your messages, photos, videos, files, etc., or any queries raised on the Platform. You should be aware that the Personal Information and any Sensitive Personal Information that you voluntarily disclose on the chat facility and that is accessible to other users could be collected and disclosed by others. We cannot take any responsibility for such collection and disclosure.
              </p>
              <br />
              <p className="text-[15px] text-qgraytwo leading-7">
                **Voice calls:** We may record Your voice calls made to our customer care number for maintaining quality and for audit purposes.
              </p>
              <br />
              <p className="text-[15px] text-qgraytwo leading-7">
                **Log Information:** We collect Non-Personal Information about Your Account such as log files, website, and performance logs and reports when You use our Services or interact with others using our Services.
              </p>
              <br />
              <p className="text-[15px] text-qgraytwo leading-7">
                **Transactional Information:** If You undertake a transaction on Our Platform, we may receive information and confirmations, such as payment receipts, billing address, product information and images, and data from third-party payment aggregators or gateways processing Your payment.
              </p>
              <br />
              <p className="text-[15px] text-qgraytwo leading-7">
                **Device and Connection Information:** We collect device-specific Non-Personal Information such as e-mail address, Internet Protocol (IP), password, browser data, computer version, operating system, purchase history, the full Uniform Resource Locators (URL) clickstream to, through, and from Our Platform (including date and time); cookie number; products You viewed or searched for; and any phone number used to call Our customer care number, including the phone number when You install our Application, access, or use Our Platform.
              </p>
              <br />
              <p className="text-[15px] text-qgraytwo leading-7">
                **Cookies:** We may use cookies to operate and provide Our services, improve Your experiences, understand how Our services are being used, and customize Our services. We may use cookies to know Your choices, such as Your language preferences, and customize Our services for You. You may refuse to accept browser cookies by activating the appropriate setting on Your browser.
              </p>
              <br />
              <p className="text-[15px] text-qgraytwo leading-7">
                **Status Information:** We collect information about Your online and status message changes on our Services, such as Your “online status,” Your “last seen status,” and Your last updated status message.
              </p>
              <br />
              <p className="text-[15px] text-qgraytwo leading-7">
                **Third-Party Information:** We receive information about You which is provided by other persons, which may include Your phone number from their mobile address book, or messages sent to groups to which You belong; the updated delivery and address information from Our logistics service provider or other affiliated service providers, Account information, purchase or return information; search results and links, including paid listings and credit history information from credit bureaus, which we use, inter alia, to help prevent and detect fraud and to offer certain credit or financial services to some users. We work with third-party providers who help Us in operating, improving, understanding, customizing, supporting, and marketing Our services. When You use third-party services, their own terms and privacy policies will govern Your use of those services.
              </p>
              <br />
              <p className="text-[15px] text-qgraytwo leading-7">
                **Sharing of Personal Information**
                Your Personal Information or Sensitive Personal Information is an important part of our business, and we share the same with other entities only as described below:
              </p>
              <br />
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                1.Affiliated Services:
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                We may share your Personal Information or Sensitive Personal Information with our affiliate service providers such as logistics, financing, insurance, and private entities offering data validation services, data processing services, onboarding services, and government and semi-government agencies, etc. These entities perform functions for you on the Platform or in connection with processing credit card and other modes of payments, providing transactional services (pertaining to the products and services available on our Platform), providing customer care services, product listing services, facilitating our brand outreach programs, or analyzing your data. These entities will only have access to your Personal Information or Sensitive Personal Information that is needed to perform their functions, and they may not use the said information for any other purpose. Further, these entities will be required to process your Personal Information or Sensitive Personal Information in accordance with this Privacy Policy and as permitted by the applicable law.
              </p>
              <br />
              <h2 className="text-[18px] font-medium text-qblack mb-5">2.Promotional Offers/Marketing/Advertisement:</h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                We may share your Personal Information with our affiliates for the purposes of marketing outreach and advertisement. However, we will not disclose your Sensitive Personal Information to them for marketing purposes without obtaining prior explicit consent from you via email or an alert in your Account.
              </p>
              <br />
              <h2 className="text-[18px] font-medium text-qblack mb-5">3.Business Purpose</h2>
              <p className="text-[15px] text-qgraytwo leading-7">We may share your Personal Information or Sensitive Personal Information with another business entity should we (or our assets) plan to raise capital or seek investment, merge with, or be acquired by that business entity, or in case of re-organization, amalgamation, or restructuring of our business. Should such a transaction be undertaken, such business entity (or the new merged/restructured entity) will be required to follow this Policy with respect to your Personal Information.</p>
              <br />
              <h2 className="text-[18px] font-medium text-qblack mb-5">4Legal Compliance</h2>
              <p className="text-[15px] text-qgraytwo leading-7">We may share your Personal Information or Sensitive Personal Information to comply with applicable law, enforce our Policies and Agreements, or when directed by any court, tribunal, regulator, or any government authority or legal process. We may also share your Personal Information or Sensitive Personal Information with other entities and government authorities for detecting, mitigating, and investigating fraudulent activities related to our Platform.</p>
              <br />
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                5.With Your Consent
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify"> Other than as set out above, you will receive prior written intimation/notice via email or alert in your Account when information about you might be disclosed or transferred to third parties, and you will have an opportunity to choose not to share/transfer such information.</p>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">By using or continuing to use the Platform, you agree to our use of your Personal Information and Sensitive Personal Information in accordance with this Privacy Policy, as may be amended from time to time by us in our discretion. You also agree and consent to us collecting, storing, processing, transferring, and disclosing your Personal Information and Sensitive Personal Information with third parties or affiliate service providers for the purposes set out in this Privacy Policy.</p>
              <br />
              <h2 className="text-[18px] font-medium text-qblack mb-5">Links to Third-party Websites</h2>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">Our Platform may provide links to other websites, many of which have their own privacy policies. Be sure to review the privacy policy on any such redirected website you’re visiting. Some content or applications, including advertisements on our Platform accessed by you while availing our services, are served by third parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or other tracking technologies to collect information about you when you use their services. The information they collect may be associated with your Personal Information, or they may collect information about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. We do not control these third parties’ tracking technologies or how they may use the data which they have accessed. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider/advertiser directly.</p>
              <br />
              <h2 className="text-[18px] font-medium text-qblack mb-5">Managing Your Account and Retention of Your Personal Information or Sensitive Personal Information</h2>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">
                You may delete your Account at any time or revoke your consent to our use of the information provided to us using our Platform's delete account feature.
              </p>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">We may retain some of your Personal Information or Sensitive Personal Information after the deletion of your Account or such data, only for legitimate business or legal purposes such as security, fraud prevention, or financial record-keeping, or as otherwise mandated under applicable law. When you delete your Account or such data, we follow a deletion process to ensure that your data is safely and completely removed from our servers or retained only in anonymized form.</p>
              <br />
              <h2 className="text-[18px] font-medium text-qblack mb-5">Options Available to You at Any Time While Using the Platform</h2>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">You will always have access to your Account information and your purchase transactions on our Platform for the limited purpose of viewing and, in certain cases, updating that information. This list will change as our Platform evolves. In addition, you may at any time while using the Platform</p>
              <ul>
                <li className="text-[15px] text-qgraytwo leading-7 text-justify">Choose not to provide any information sought to be collected.</li>
                <li className="text-[15px] text-qgraytwo leading-7 text-justify">Request to review and correct, amend, or update your Personal Information or Sensitive Personal Information. When you update such information, we usually keep a copy of the previous version for our records.</li>
                <li className="text-[15px] text-qgraytwo leading-7 text-justify">Withdraw your consent given earlier to our Platform to use your Personal Information or Sensitive Personal Information. However, such withdrawal of consent must be provided to us in writing.</li>
              </ul>
              <br />
              <h2 className="text-[18px] font-medium text-qblack mb-5">Data Security</h2>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">
                We have employed robust, reasonable, and up-to-date physical, electronic, procedural, technical, and administrative security measures to protect your Personal Information and Sensitive Personal Information from loss, theft, unauthorized, and unlawful interception and dissemination. Your information is stored on servers secured using Microsoft Azure. We maintain the security of your information as per international standards.
              </p>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">
                We request you to exercise caution if you are providing your Personal Information or Sensitive Personal Information over a chat forum or any publicly accessible part of the Platform. We urge you not to share your Account details and password with anyone. It is your responsibility to ensure your Account details remain confidential and that no unauthorized person gains access to them.
              </p>
              <br />
              <h2 className="text-[18px] font-medium text-qblack mb-5">Conditions of Use</h2>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">
                If you choose to avail of our services, such services and any dispute over privacy are subject to this Privacy Policy and our terms of use, including limitations on damages, resolution of disputes, and the application of the laws of India.
              </p>
              <br />
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                Limitation on Damages
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">
                We shall be liable to pay actual damages only for such offenses as are envisaged under the Act. You shall have the right to claim damages on account of any non-compliance by us, subject to sharing with us satisfactory legal evidence of our non-compliance and the validity of your claim. Your right to claim shall be limited to statutory damages prescribed under the Act.
              </p>
              <br/>
              <h2 className="text-[18px] font-medium text-qblack mb-5">Disputes</h2>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">
                Any disputes arising on account of or out of this Privacy Policy shall be governed by the laws of India and the courts in Delhi shall have exclusive and rightful jurisdiction to adjudicate upon all disputes arising out of this Privacy Policy.
              </p>
              <br/>
              <h2 className="text-[18px] font-medium text-qblack mb-5">Grievance Officer</h2>
              <p className="text-[15px] text-qgraytwo leading-7 text-justify">
                In accordance with the Information Technology Act 2000 and rules made thereunder, the name and contact details of the Grievance Officer are provided below
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
