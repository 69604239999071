export default function InputCom({
  label,
  type,
  name,
  id,
  onBlur,
  placeholder,
  children,
  onChange,
  value,
  inputClasses,
  labelClasses = "text-qgray text-[13px] font-normal",
}) {
  return (
    <div className="input-com w-full h-full">
      {label && (
        <label
          className={`input-label capitalize block  mb-1 ${labelClasses || ""}`}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div className="input-wrapper border border-qgray-border w-full h-full overflow-hidden relative ">
        <input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={`input-field ptb-7 placeholder:text-sm text-md px-6 text-dark-gray w-full h-12 font-normal bg-white focus:ring-0 focus:outline-none ${
            inputClasses || ""
          }`}
          type={type}
          id={id}
          name={name}
        />
        {children && children}
      </div>
    </div>
  );
}
