import { readuxConstant } from "../constants";

const initialvalue = {};

export const singleProduct = (state = initialvalue, action) => {
  switch (action.type) {
    case readuxConstant.SINGLE_PRODUCT:
      return action.value;
    default:
      return state;
  }
};
