import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function TermsCondition() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Terms and condition", path: "/terms-conditions" },
            ]}
            title="Terms and Condition"
          />
        </div>
        <div className="w-full px-6">
          <ul class="list-disc list-inside space-y-4 text-gray-700">
            <li>
              By signing up, accessing, browsing, or otherwise using the Platform, you indicate agreement to all terms and conditions herein. Please read these Terms of Use carefully before proceeding. By accepting these Terms, you also accept and agree to be bound by other policies (Privacy Policy, Intellectual Property Policy, Terms of Sale, Return policy), as amended from time to time.
            </li>
            <li>
              This document is published in accordance with Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011, requiring publishing the rules and regulations, Privacy Policy, and Terms of Use for accessing the domain name [www.ezybuying.in].
            </li>
            <li>
              The Platform, under Ezybuying.in, operates as a B2B marketplace for construction and industrial products and services. Services include (a) marketplace operations, (b) logistical support through identified logistics partners, and (c) financing options for Platform users through financial service partners.
            </li>
            <li>
              Terms like "We", "Us", and "Our" refer to Ezybuying.in. "You" or "User" refers to individuals or entities accessing the Platform or registering as buyers or sellers by agreeing to these Terms.
            </li>
            <li>
              These Terms include all detailed terms and any other rules, guidelines, policies, terms, and conditions as updated from time to time and published on the Platform.
            </li>
            <li>
              For any queries regarding these Terms, please contact us at [Insert Contact Email] or write to Our Nodal Officer or Grievance Officer.
            </li>
            <li>
              These Terms are subject to changes due to updates in applicable laws, technological standards, and security standards. We may alter, modify, add, or delete these Terms without prior notice. Please review these Terms periodically to stay informed of changes.
            </li>
            <li>
              This Platform enables you to purchase listed products at indicated prices, or as agreed upon, from any location. The Platform acts solely as a facilitator and is not a party to transactions. The contract of sale is strictly between you and the counterparty.
            </li>
            <li>
              This document is an electronic record under the Information Technology Act, 2000, and does not require physical or digital signatures.
            </li>
          </ul>
          <br/>
          <h1 class="text-2xl font-semibold mb-6">Platform Terms and Conditions</h1>

          <h2 class="text-xl font-semibold mb-4">Services</h2>
          <ul class="list-disc list-inside space-y-4 text-gray-700">
            <li>
              The website operates as an electronic marketplace and intermediary, providing a platform for merchants/vendors/sellers ("Seller") to advertise, exhibit, make available, and offer various products to users/buyers/customers ("Buyer").
            </li>
            <li>
              The platform enables users to accept offers from Sellers and make payments for the purchase of products.
            </li>
            <li>
              Provides services to facilitate interactions and engagements between Buyers and Sellers.
            </li>
            <li>
              Offers logistics services to facilitate transactions between Buyers and Sellers.
            </li>
            <li>
              Facilitates financing for platform users.
            </li>
            <li>
              Provides any other incidental and ancillary services as necessary.
            </li>
          </ul>

          <h2 class="text-xl font-semibold mt-8 mb-4">Membership Eligibility and Other Conditions</h2>
          <ul class="list-disc list-inside space-y-4 text-gray-700">
            <li>
              Full functionality of the Platform is available only to persons capable of entering legally binding contracts under the Indian Contract Act, 1872. The Platform prohibits minors from registering as users.
            </li>
            <li>
              Ezybuying.in reserves the right to charge fees for services, in Indian Rupees. Charges may vary by user, based on specified terms and conditions or documents provided by the Platform.
            </li>
            <li>
              Fees or other charges are subject to change, with notice provided via email to the registered address of the Seller or Buyer. Users may choose to deactivate products or terminate their Platform membership if charges are not acceptable.
            </li>
          </ul>

          <h2 class="text-xl font-semibold mt-8 mb-4">Your Registration and Account Obligations</h2>
          <ul class="list-disc list-inside space-y-4 text-gray-700">
            <li>
              Certain Platform functionalities are accessible only to registered users. Users are responsible for actions undertaken through their account and must maintain confidentiality to prevent unauthorized access.
            </li>
            <li>
              Users must promptly report unauthorized access or security issues, technical difficulties, or incorrect rights on the Platform.
            </li>
            <li>
              Users are required to provide accurate personal information as part of registration. The Platform reserves the right to suspend accounts with inaccurate or outdated information.
            </li>
            <li>
              Users representing a juristic person confirm they are authorized representatives. The Platform may require supporting documents to verify this authority.
            </li>
          </ul>

          <h2 class="text-xl font-semibold mt-8 mb-4">Communications</h2>
          <ul class="list-disc list-inside space-y-4 text-gray-700">
            <li>
              By contacting the Platform via customer care, email, or phone, users consent to receive electronic or telephonic communication from the Platform.
            </li>
            <li>
              Registered users agree to receive communication through email, SMS, WhatsApp, or calls for purposes including promotions, offers, transaction details, surveys, and official correspondences.
            </li>
            <li>
              The Platform may share information for purposes like product offers, marketing, referrals, and expanding business opportunities, in accordance with the Privacy Policy.
            </li>
          </ul>

          <section class="mb-4">
            <h2 class="text-xl font-semibold text-gray-700">1. License to Use the Platform</h2>
            <p class="text-gray-600">
              Users are granted a limited, non-transferable license to access <strong>Ezybuying.in</strong> as long as they comply with the platform’s policies. Unauthorized actions, such as reverse engineering, resale, or unauthorized data extraction, may result in immediate termination of this license.
            </p>
          </section>

          <section class="mb-4">
            <h2 class="text-xl font-semibold text-gray-700">2. Prohibited Usage</h2>
            <p class="text-gray-600">
              Users must not engage in activities that could damage the platform, harm other users, or violate any applicable laws. Specific behaviors like harassment, impersonation, using unauthorized payment information, or uploading harmful content are strictly prohibited.
            </p>
          </section>

          <section class="mb-4">
            <h2 class="text-xl font-semibold text-gray-700">3. Pricing Information</h2>
            <p class="text-gray-600">
              <strong>Ezybuying.in</strong> strives for accurate pricing, but errors may occur. The platform reserves the right to cancel or adjust orders in cases of pricing errors.
            </p>
          </section>

          <section class="mb-4">
            <h2 class="text-xl font-semibold text-gray-700">4. Ranking and Search Parameters</h2>
            <p class="text-gray-600">
              Product rankings may be influenced by various factors, including relevance, availability, customer ratings, and paid promotions.
            </p>
          </section>

          <section class="mb-4">
            <h2 class="text-xl font-semibold text-gray-700">5. Payments and Logistics</h2>
            <p class="text-gray-600">
              Users are responsible for providing correct payment information. <strong>Ezybuying.in</strong> is not liable for third-party payment processing issues or delays.
              Logistics may be handled by third-party providers, and <strong>Ezybuying.in</strong> is not responsible for delivery issues caused by these third parties.
            </p>
          </section>

          <section class="mb-4">
            <h2 class="text-xl font-semibold text-gray-700">6. Financing Services</h2>
            <p class="text-gray-600">
              The platform collaborates with banks for financing, acting only as a facilitator. <strong>Ezybuying.in</strong> is not liable for issues between users and financial institutions.
            </p>
          </section>

          <section class="mb-4">
            <h2 class="text-xl font-semibold text-gray-700">7. User Conduct in Chat and Forums</h2>
            <p class="text-gray-600">
              The platform may provide communication channels for users to interact. Users are expected to maintain respect and decorum, refraining from offensive or illegal content.
            </p>
          </section>

          <section class="mb-4">
            <h2 class="text-xl font-semibold text-gray-700">8. User-Generated Content</h2>
            <p class="text-gray-600">
              Users grant <strong>Ezybuying.in</strong> rights to use any content they post. <strong>Ezybuying.in</strong> has the right to remove content that violates platform policies.
            </p>
          </section>

          <section class="mb-4">
            <h2 class="text-xl font-semibold text-gray-700">9. Privacy and Intellectual Property</h2>
            <p class="text-gray-600">
              <strong>Ezybuying.in</strong> commits to safeguarding user privacy and complying with data protection laws.
              The platform’s trademarks and copyrighted materials are protected by law. Unauthorized reproduction or distribution is prohibited.
            </p>
          </section>

          <section>
            <h2 class="text-xl font-semibold text-gray-700">10. Indemnification and Anti-Corruption</h2>
            <p class="text-gray-600">
              Users agree to indemnify <strong>Ezybuying.in</strong> for any third-party claims resulting from breaches of platform terms. Compliance with anti-corruption and anti-money laundering laws is mandatory.
            </p>
          </section>

          <div class="container mx-auto py-10 bg-white">
            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Disclaimer</h2>
              <p class="mb-4">You hereby expressly acknowledge and undertake that You are accessing the <strong>Ezybuying.in</strong> Platform and transacting solely at Your own risk, using Your best judgment before entering into any transaction through the Platform...</p>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Limitation of Liability</h2>
              <ul class="list-disc ml-8 mb-4">
                <li>Your use or failure to use this Platform and services provided by it;</li>
                <li>Obtaining substitute products or services, identical or not, at a lesser price;</li>
                <li>Any action or representation of any third party;</li>
              </ul>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Viruses and Malwares</h2>
              <p class="mb-4">The possibility of transmission of viruses and other malware over the internet will always persist. We assure You that We undertake best efforts to prevent any such malware transmission...</p>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Termination</h2>
              <p class="mb-4"><strong>Ezybuying.in</strong> may, at its discretion, change, discontinue, modify, restrict, suspend, or terminate the Platform or any part of it without any notice...</p>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Term</h2>
              <p class="mb-4">The Terms will continue to apply until terminated by either You or <strong>Ezybuying.in</strong> as set forth below...</p>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Principal to Principal Relationship</h2>
              <p class="mb-4"><strong>Ezybuying.in</strong> shall not have any right to conclude any contract for the sale or purchase of Products for and/or on Your behalf...</p>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Grievance Officer</h2>
              <p class="mb-4">In compliance with the Information Technology Act, 2000, and the rules made thereunder, any complaints or concerns can be directed to Mr. Preraki Arora...</p>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Representations and Warranties of the Seller</h2>
              <ol class="list-decimal ml-8 mb-4">
                <li>The Seller shall not sell a product that is prohibited to be sold under the applicable laws on the Platform.</li>
                <li>The Seller shall ensure that the Seller and his agents provide true, correct, and accurate descriptions of all the products...</li>
                <li>The Seller shall ensure and obtain all requisite authorizations, approvals, and consents to sell...</li>
              </ol>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Additional Conditions</h2>
              <p class="mb-4">You accept that the rights and obligations contained in the Terms, as well as any other documents that are incorporated by way of reference...</p>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Governing Law</h2>
              <p class="mb-4">These Terms will be governed by and construed in accordance with the laws of India...</p>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Waiver</h2>
              <p class="mb-4">No failure, delay, or omission on the part of <strong>Ezybuying.in</strong>, us, or any of our affiliates to act against any breach by you of the Terms hereunder shall operate as a waiver...</p>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Severability</h2>
              <p class="mb-4">If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law...</p>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Complete Agreement</h2>
              <p class="mb-4">These Terms of <strong>Ezybuying.in</strong> represent the entire understanding relating to the use of the Platform...</p>
            </section>

            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Force Majeure</h2>
              <p class="mb-4"><strong>Ezybuying.in</strong> or our affiliates shall be under no liability whatsoever to any User in the event the performance of Services/Platform is prevented...</p>
            </section>


            <section class="mb-8">
              <h2 class="text-2xl font-semibold text-gray-900 mb-4">Conflict</h2>
              <p class="mb-4">In the event of any conflict or inconsistency between these Terms of Use and any other document, these Terms of Use shall prevail...</p>
            </section>
          </div>



        </div>
      </div>
    </Layout>
  );
}
