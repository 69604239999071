import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import * as Constants from "../../../../Constants/index.js";

export default function OrderTab() {
  if (localStorage.getItem("isCustomerLoggedIn") !== "true") {
    return <Navigate to="/login" />;
  }

  const loginCustomer = JSON.parse(localStorage.getItem("customer_row")) || [];

  const [ordersData, setOrdersData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const requestData = { customerData: loginCustomer };
        const resp = await axios.post(Constants.api + "orders", requestData);
        if (resp.data.success) {
          setOrdersData(resp.data.orders);
        } else {
          return <Navigate to="/login" />; //its check login or not from api
        }
      } catch (err) {
        console.error("An error occurred:", err);
      }
    };

    fetchOrders();
  }, []);

  function formatDateToDmy(dateString) {
    if (dateString == "" || dateString == null) {
      return "";
    }
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is 0-based, so add 1
    const year = date.getFullYear();

    // Ensure that day and month have leading zeros if needed
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  function ProductDetailsTable({ orderPrd, onClose }) {
    console.log(orderPrd.product_array);
    return (
      <div>
        <button onClick={onClose}>
          <FontAwesomeIcon icon={faArrowLeft} /> Orders
        </button>

        <div className="dashboard-info mt-8 flex items-center bg-primarygray px-7 py-7">
          <div className="">
            <p className="title text-[22px] font-semibold">Order Details</p>
            <div className="mt-5">
              <table>
                <tr className="mt-5 mb-5">
                  <td className="text-base text-qgraytwo w-[150px] block">
                    <div>Order ID#:</div>
                  </td>
                  <td className="text-base text-qblack font-medium">
                    {orderPrd.unique_id}
                  </td>
                </tr>
                <tr className="mt-5 mb-5">
                  <td className="text-base text-qgraytwo w-[150px] block">
                    <div>Order Status:</div>
                  </td>
                  <td className="text-base text-qblack font-medium">
                    {orderPrd.order_status}
                  </td>
                </tr>
                <tr className="mt-5 mb-5">
                  <td className="text-base text-qgraytwo w-[150px] block">
                    <div>Order Amount:</div>
                  </td>
                  <td className="text-base text-qblack font-medium">
                    ₹{(orderPrd.order_amount > 0) ? orderPrd.order_amount : 0}
                  </td>
                </tr>
                <tr className="mt-5 mb-5">
                  <td className="text-base text-qgraytwo w-[150px] block">
                    <div>Order Date:</div>
                  </td>
                  <td className="text-base text-qblack font-medium">
                    {formatDateToDmy(orderPrd.created_at)}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="w-[1px] h-[164px] bg-[#E4E4E4]"></div>
          <div className="ml-6">
            <p className="title text-[22px] font-semibold">Address Info</p>
            <div className="mt-5">
              <table>
                <tr className="mt-5 mb-5">
                  <td className="text-base text-qgraytwo w-[150px] block">
                    <div>Address:</div>
                  </td>
                  <td className="text-base text-qblack font-medium w-[150px]">
                    {orderPrd.address_one}
                  </td>
                </tr>
                <tr className="mt-5 mb-5">
                  <td className="text-base text-qgraytwo w-[150px] block">
                    <div>Pincode:</div>
                  </td>
                  <td className="text-base text-qblack font-medium">
                    {orderPrd.pincode}
                  </td>
                </tr>
                <tr className="mt-5 mb-5">
                  <td className="text-base text-qgraytwo w-[150px] block">
                    <div>City:</div>
                  </td>
                  <td className="text-base text-qblack font-medium">
                    {orderPrd.city_name}
                  </td>
                </tr>
                <tr className="mt-5 mb-5">
                  <td className="text-base text-qgraytwo w-[150px] block">
                    <div>State:</div>
                  </td>
                  <td className="text-base text-qblack font-medium">
                    {orderPrd.state_name}
                  </td>
                </tr>
                <tr className="mt-5 mb-5">
                  <td className="text-base text-qgraytwo w-[150px] block">
                    <div>Country:</div>
                  </td>
                  <td className="text-base text-qblack font-medium">
                    {orderPrd.country_name}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <h2 className="mt-5">Order Product Details</h2>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            {/* table heading */}
            <tr className="text-base text-qgray whitespace-nowrap px-2 border-b default-border-bottom ">
              <td className="py-4 whitespace-nowrap text-center">
                Product Name
              </td>
              <td className="py-4 whitespace-nowrap text-center">Quantity</td>
              <td className="py-4 whitespace-nowrap text-center">
                Base Amount
              </td>
              <td className="py-4 whitespace-nowrap text-center">
                Discount Amount
              </td>
              {/* <td className="py-4 whitespace-nowrap  text-center">
                GST Amount
              </td> */}
              <td className="py-4 whitespace-nowrap  text-center">
                Sub Total
              </td>
            </tr>
            {/* table heading end */}
            {orderPrd.product_array.length > 0 &&
              orderPrd.product_array.map((prdRow) => {
                return (
                  <tr className="bg-white border-b hover:bg-gray-50">
                    <td className="text-center py-4">
                      <span className="text-lg text-qgray font-medium">
                        {prdRow.product_name}
                      </span>
                    </td>
                    <td className="text-center py-4 px-2">
                      <span className="text-base text-qgray  whitespace-nowrap">
                        {prdRow.quantity}
                      </span>
                    </td>

                    <td className="text-center py-4 px-2">
                      <span className="text-base text-qblack whitespace-nowrap px-2 ">
                        ₹{prdRow.base_amount > 0 ? prdRow.base_amount : 0}
                      </span>
                    </td>
                    <td className="text-center py-4 px-2">
                      <span className="text-base text-qblack whitespace-nowrap px-2 ">
                        {prdRow.discount_price > 0 ? '₹' + prdRow.discount_price : ''}
                      </span>
                    </td>
                    {/* <td className="text-center py-4 px-2">
                      <span className="text-base text-qblack whitespace-nowrap px-2 ">
                        ₹{prdRow.gst_amount > 0 ? prdRow.gst_amount : 0}
                      </span>
                    </td> */}
                    <td className="text-center py-4 px-2">
                      <span className="text-base text-qblack whitespace-nowrap px-2 ">
                        ₹{prdRow.sub_total > 0 ? prdRow.sub_total : 0}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3"></td>
              <td className="text-right px-2">Order Amount</td>
              <td className="text-center px-2">₹{(orderPrd.order_amount > 0) ? orderPrd.order_amount : 0}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td className="text-right px-2">Cartage Cost</td>
              <td className="text-center px-2">₹{(orderPrd.cartage_cost > 0) ? orderPrd.cartage_cost : 0}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td className="text-right px-2">Labour Cost</td>
              <td className="text-center px-2">₹{(orderPrd.labour_cost > 0) ? orderPrd.labour_cost : 0}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td className="text-right px-2">Other Cost</td>
              <td className="text-center px-2">₹{(orderPrd.other_cost > 0) ? orderPrd.other_cost : 0}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td className="text-right px-2">CGST@9%</td>
              <td className="text-center px-2">₹{(orderPrd.cgst_amount > 0) ? orderPrd.cgst_amount : 0}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td className="text-right px-2">SGST@9%</td>
              <td className="text-center px-2">₹{(orderPrd.sgst_amount > 0) ? orderPrd.sgst_amount : 0}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td className="text-right px-2">IGST@18%</td>
              <td className="text-center px-2">₹{(orderPrd.igst_amount > 0) ? orderPrd.igst_amount : 0}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td className="text-right px-2">Total Amount</td>
              <td className="text-center px-2"><b>₹{(orderPrd.total_amount > 0) ? orderPrd.total_amount : 0}</b></td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }

  return (
    <>
      <div className="relative w-full overflow-x-auto sm:rounded-lg">
        {selectedOrder ? (
          <ProductDetailsTable
            orderPrd={selectedOrder}
            onClose={() => setSelectedOrder(null)}
          />
        ) : (
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <tbody>
              {/* table heading */}
              <tr className="text-base text-qgray whitespace-nowrap px-2 border-b default-border-bottom ">
                <td className="py-4 whitespace-nowrap text-center">
                  Order ID#
                </td>
                <td className="py-4 whitespace-nowrap text-center">
                  Order Date
                </td>
                <td className="py-4 whitespace-nowrap text-center">
                  Delivery Date
                </td>
                <td className="py-4 whitespace-nowrap text-center">Status</td>
                <td className="py-4 whitespace-nowrap text-center">Total Amount</td>
                <td className="py-4 whitespace-nowrap  text-center">Action</td>
              </tr>
              {/* table heading end */}
              {ordersData.length > 0 &&
                ordersData.map((orderRow) => {
                  return (
                    <tr className="bg-white border-b hover:bg-gray-50" key={orderRow.id}>
                      <td className="text-center py-4">
                        <span className="text-lg text-qgray font-medium">
                          {orderRow.unique_id}
                        </span>
                      </td>
                      <td className="text-center py-4 px-2">
                        <span className="text-base text-qgray  whitespace-nowrap">
                          {formatDateToDmy(orderRow.created_at)}
                        </span>
                      </td>
                      <td className="text-center py-4 px-2">
                        <span className="text-base text-qgray  whitespace-nowrap">
                          {formatDateToDmy(orderRow.delivery_date)}
                        </span>
                      </td>
                      <td className="text-center py-4 px-2">
                        <span className="text-sm rounded text-green-500 bg-green-100 p-2">
                          {orderRow.order_status}
                        </span>
                      </td>
                      <td className="text-center py-4 px-2">
                        <span className="text-base text-qblack whitespace-nowrap px-2 ">
                          ₹
                          {orderRow.order_amount > 0
                            ? orderRow.order_amount
                            : 0}
                        </span>
                      </td>
                      <td className="text-center py-4">
                        <button
                          onClick={() => setSelectedOrder(orderRow)}
                          type="button"
                          className="w-[116px] h-[46px] bg-qyellow text-qblack font-bold"
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}
