import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import * as Constants from "../../../Constants/index.js";
import InputCom from "../../Helpers/InputCom";
import Layout from "../../Partials/Layout";
import Thumbnail from "./Thumbnail";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "../../Toast.js";

export default function Signup() {
  if (localStorage.getItem("isCustomerLoggedIn") === "true") {
    return <Navigate to="/profile" />;
  }

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [checked, setValue] = useState(false);
  const rememberMe = () => {
    setValue(!checked);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      customer_type: "BUYER",
      company_name: "",
      gst_number: "",
      pan_number: "",
    },
    validationSchema: Yup.object({
      customer_type: Yup.string().required("Customer type is required"),
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      mobile: Yup.string()
        .matches(/^[0-9]+$/, "Mobile must only contain digits")
        .min(10, "Mobile must be at least 10 digits")
        .max(10, "Mobile must not exceed 10 digits")
        .required("Mobile is required"),
      company_name: Yup.string().required("Company name is required"),
      // gst_number: Yup.string()
      //   .matches(
      //     /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      //     "Invalid GST number"
      //   )
      //   .required("GST number is required"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      // You can send the registration data to your API here using axios
      axios
        .post(Constants.api + "register", values)
        .then((response) => {
          if (response && response.data && response.data.success) {
            console.log(response.data.message);
            toast.success(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
              onClose: () => {
                setIsLoading(false);
                navigate("/login");
              },
            });
          } else {
            toast.error(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Registration failed", error);
          setIsLoading(false);
        });
    },
  });

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="login-page-wrapper w-full py-10">
        <div className="container-x mx-auto">
          <div className="lg:flex items-center relative">
            <div className="lg:w-[572px] w-full lg:h-full bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
              <div className="w-full">
                <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                  <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                    Create Account
                  </h1>
                  <div className="shape -mt-6">
                    <svg
                      width="354"
                      height="30"
                      viewBox="0 0 354 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 28.8027C17.6508 20.3626 63.9476 8.17089 113.509 17.8802C166.729 28.3062 341.329 42.704 353 1"
                        stroke="#576ec7"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="input-area">
                    <div className="input-item mb-5 mt-6">
                      <label>
                        <input
                          name="customer_type"
                          type="radio"
                          value="BUYER"
                          checked={formik.values.customer_type === "BUYER"}
                          onChange={formik.handleChange}
                        />
                        BUYER
                      </label>

                      {/* <label>
                        <input
                          name="customer_type"
                          type="radio"
                          value="SELLER"
                          checked={formik.values.customer_type === "SELLER"}
                          onChange={formik.handleChange}
                        />
                        SELLER
                      </label> */}
                      {formik.touched.customer_type &&
                        formik.errors.customer_type ? (
                        <span classcustomer_type="errorMsg ">
                          {formik.errors.customer_type}
                        </span>
                      ) : null}
                    </div>

                    <div className="input-item mb-5 mt-6">
                      <InputCom
                        placeholder="Full Name"
                        label="Full Name*"
                        type="text"
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        inputClasses="h-10"
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <span className="errorMsg ">{formik.errors.name}</span>
                      ) : null}
                    </div>

                    <div className="input-item mb-5 mt-6">
                      <InputCom
                        placeholder="Email Address"
                        label="Email Address*"
                        type="email"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        inputClasses="h-10"
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <span className="errorMsg ">{formik.errors.email}</span>
                      ) : null}
                    </div>

                    <div className="input-item mb-5 mt-6">
                      <InputCom
                        placeholder="Mobile Number"
                        label="Mobile Number*"
                        type="text"
                        id="mobile"
                        name="mobile"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobile}
                        className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none`}
                      />
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <span className="errorMsg ">
                          {formik.errors.mobile}
                        </span>
                      ) : null}
                    </div>

                    <div className="input-item mb-5 mt-6">
                      <InputCom
                        placeholder="Company Name"
                        label="Company Name*"
                        type="text"
                        id="company_name"
                        name="company_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.company_name}
                        className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none`}
                      />
                      {formik.touched.company_name &&
                        formik.errors.company_name ? (
                        <span className="errorMsg ">
                          {formik.errors.company_name}
                        </span>
                      ) : null}
                    </div>

                    <div className="input-item mb-5 mt-6">
                      <InputCom
                        placeholder="GST Number"
                        label="GST Number"
                        type="text"
                        id="gst_number"
                        name="gst_number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.gst_number}
                        className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none`}
                      />
                      {/* {formik.touched.gst_number && formik.errors.gst_number ? (
                        <span className="errorMsg ">
                          {formik.errors.gst_number}
                        </span>
                      ) : null} */}
                    </div>
                    <div className="input-item mb-5 mt-6">
                      <InputCom
                        placeholder="Pan Number"
                        label="Pan Number"
                        type="text"
                        id="pan_number"
                        name="pan_number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.pan_number}
                        className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none`}
                      />
                      {/* {formik.touched.pan_number && formik.errors.pan_number ? (
                        <span className="errorMsg ">
                          {formik.errors.pan_number}
                        </span>
                      ) : null} */}
                    </div>

                    {/* <div className="input-item mb-5">
                      <h6 className="input-label text-qgray capitalize text-[13px] font-normal block mb-1 ">
                        Country*
                      </h6>
                      <div className="w-full h-[50px] border border-[#EDEDED] px-5 flex justify-between items-center mb-1">
                        <span className="text-[13px] text-qgraytwo">
                          Select Country
                        </span>
                        <span>
                          <svg
                            width="11"
                            height="7"
                            viewBox="0 0 11 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.4 6.8L0 1.4L1.4 0L5.4 4L9.4 0L10.8 1.4L5.4 6.8Z"
                              fill="#222222"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div className="input-item mb-5">
                      <InputCom
                        placeholder="Your address Here"
                        label="Address*"
                        name="address"
                        type="text"
                        inputClasses="h-10"
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <div className="w-1/2">
                        <h6 className="input-label text-qgray capitalize text-[13px] font-normal block mb-1 ">
                          Town / City*
                        </h6>
                        <div className="w-full h-[50px] border border-[#EDEDED] px-5 flex justify-between items-center mb-1">
                          <span className="text-[13px] text-qgraytwo">
                            Maiyami
                          </span>
                          <span>
                            <svg
                              width="11"
                              height="7"
                              viewBox="0 0 11 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.4 6.8L0 1.4L1.4 0L5.4 4L9.4 0L10.8 1.4L5.4 6.8Z"
                                fill="#222222"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="w-full h-[50px] mb-5 sm:mb-0">
                          <InputCom
                            label="Postcode / ZIP*"
                            inputClasses="w-full h-full"
                            type="text"
                            placeholder="00000"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="forgot-password-area mb-7">
                      <div className="remember-checkbox flex items-center space-x-2.5">
                        <button
                          onClick={rememberMe}
                          type="button"
                          className="w-5 h-5 text-qblack flex justify-center items-center border border-light-gray"
                        >
                          {checked && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                        </button>
                        <span
                          onClick={rememberMe}
                          className="text-base text-black"
                        >
                          I agree all
                          <span className="text-qblack">tarm and condition</span>
                          in BigShop.
                        </span>
                      </div>
                    </div> */}
                    <div className="signin-area mb-5 mt-6">
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          disabled={isLoading}
                          className="black-btn text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center send-now"
                        >
                          <span>
                            {isLoading ? "Creating..." : "Create Account"}
                          </span>
                        </button>
                      </div>
                    </div>

                    <div className="signup-area flex justify-center">
                      <p className="text-base text-qgraytwo font-normal">
                        Alrady have an Account?
                        <Link to="/login" className="ml-2 text-qblack">
                          Log In
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex-1 lg:flex hidden transform scale-60 xl:scale-100   xl:justify-center">
              <div
                className="absolute xl:-right-20 -right-[138px]"
                style={{ top: "calc(50% - 258px)" }}
              >
                <Thumbnail />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
