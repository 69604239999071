import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DropdownSelect from "react-dropdown-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import * as Constants from "../../../../Constants/index.js";
import InputCom from "../../../Helpers/InputCom";
import Toast from "../../../Toast.js";

export default function AddressesTab() {
  if (localStorage.getItem("isCustomerLoggedIn") !== "true") {
    return <Navigate to="/login" />;
  }

  const loginCustomer = JSON.parse(localStorage.getItem("customer_row")) || [];

  const [addressData, setAddressData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [isAddressLoading, setIsAddressLoading] = useState(false);

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const requestData = { customerData: loginCustomer };
        const resp = await axios.post(
          Constants.api + "customer-addresses",
          requestData
        );
        if (resp.data.success) {
          setAddressData(resp.data.customer_address);
        } else {
          return <Navigate to="/login" />; //its check login or not from api
        }
      } catch (err) {
        console.error("An error occurred:", err);
      }
    };

    fetchAddress();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const resp = await axios.get(Constants.api + "states");
        if (resp.data.success) {
          setStatesData(resp.data.states);
          // console.log(resp.data.categories);
          // console.log('fasdfasdf',productCategories);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchStates();
  }, []);

  const addressSubmit = useFormik({
    initialValues: {
      state_id: "",
      city: "",
      address: "",
      pincode: "",
    },
    validationSchema: Yup.object({
      state_id: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      address: Yup.string().required("Address is required"),
      pincode: Yup.string().required("Pincode is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      values.customer_row = loginCustomer;
      setIsAddressLoading(true);
      //console.log(values);
      axios
        .post(Constants.api + "address-submit", values)
        .then((response) => {
          if (response && response.data && response.data.success) {
            setAddressData(response.data.customer_address);

            toast.success(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
              onClose: () => {
                setIsAddressLoading(false);
                addressSubmit.resetForm();
              },
            });
          } else {
            toast.error(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
            });
            setIsAddressLoading(false);
          }
        })
        .catch((error) => {
          console.error("Form submit failed", error);
          setIsAddressLoading(false);
        });
    },
  });

  const handleDeleteAddress = async (addressId) => {
    try {
      const resp = await axios.post(Constants.api + "delete-address", {
        addressId,
      });
      if (resp.data.success) {
        setAddressData((addressData) =>
          addressData.filter((addressRow) => addressRow.id !== addressId)
        );
      } else {
        console.error("Failed to delete address");
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-[30px]">
        {addressData.length > 0 &&
          addressData.map((addressRow, index) => {
            return (
              <div className="w-full bg-primarygray p-5 border">
                <div className="flex justify-between items-center">
                  <p className="title text-[22px] font-semibold">
                    Address #{index + 1}
                  </p>
                  <button
                    type="button"
                    className="border border-qgray w-[34px] h-[34px] rounded-full flex justify-center items-center"
                    onClick={() => handleDeleteAddress(addressRow.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
                <div className="mt-5">
                  <table>
                    <tbody>
                      <tr className="flex mb-3">
                        <td className="text-base text-qgraytwo w-[70px] block line-clamp-1">
                          <div>State:</div>
                        </td>
                        <td className="text-base text-qblack line-clamp-1 font-medium">
                          {addressRow.state_name}
                        </td>
                      </tr>
                      <tr className="flex mb-3">
                        <td className="text-base text-qgraytwo w-[70px] block line-clamp-1">
                          <div>City:</div>
                        </td>
                        <td className="text-base text-qblack line-clamp-1 font-medium">
                          {addressRow.city_name}
                        </td>
                      </tr>
                      <tr className="flex mb-3">
                        <td className="text-base text-qgraytwo w-[70px] block line-clamp-1">
                          <div>Address:</div>
                        </td>
                        <td className="text-base text-qblack line-clamp-1 font-medium">
                          {addressRow.address_one}
                        </td>
                      </tr>
                      <tr className="flex mb-3">
                        <td className="text-base text-qgraytwo w-[70px] block line-clamp-1">
                          <div>Pincode:</div>
                        </td>
                        <td className="text-base text-qblack line-clamp-1 font-medium">
                          {addressRow.pincode}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
      </div>

      <div className="w-full h-full mt-4 mb-4">
        <h2>Add New Address</h2>
        <form onSubmit={addressSubmit.handleSubmit}>
          <div className="input-area input-area-cart">
            <div className="input-feild">
              <div className="input-state">
                <div className="input-body input-flex">
                  <div className="input-item mb-5 input-flex">
                    <label
                      className={`input-label capitalize block  mb-1 text-qgray text-[13px] font-normal`}
                      for="state_id"
                    >
                      State*
                    </label>
                    <DropdownSelect
                      options={statesData.map((option) => ({
                        label: option.name,
                        value: option.id,
                      }))}
                      value={addressSubmit.values.state_id}
                      onChange={(selectedOptions) => {
                        // Update Formik field value
                        addressSubmit.setFieldValue(
                          "state_id",
                          selectedOptions.length > 0
                            ? selectedOptions[0].value
                            : ""
                        );
                      }}
                      searchable={true}
                      dropdownPosition="auto"
                      keepSelectedInList={true}
                      placeholder="Select State"
                    />
                    {addressSubmit.touched.state_id &&
                      addressSubmit.errors.state_id ? (
                      <span className="errorMsg">
                        {addressSubmit.errors.state_id}
                      </span>
                    ) : null}
                  </div>

                  <div className="input-item mb-5 input-flex input-height">
                    <InputCom
                      placeholder="City"
                      label="City*"
                      type="text"
                      id="city"
                      name="city"
                      onChange={addressSubmit.handleChange}
                      onBlur={addressSubmit.handleBlur}
                      value={addressSubmit.values.city}
                      className={`input-height input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none input-height`}
                    />
                    {addressSubmit.touched.city && addressSubmit.errors.city ? (
                      <span className="errorMsg mt-6">
                        {addressSubmit.errors.city}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="input-state">
                <div className="input-body input-flex">
                  <div className="input-item mb-5 input-flex input-height">
                    <InputCom
                      placeholder="Address "
                      label="Address*"
                      type="text"
                      id="address"
                      name="address"
                      onChange={addressSubmit.handleChange}
                      onBlur={addressSubmit.handleBlur}
                      value={addressSubmit.values.address}
                      className={`input-height input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none input-height`}
                    />
                    {addressSubmit.touched.address &&
                      addressSubmit.errors.address ? (
                      <span className="errorMsg mt-6">
                        {addressSubmit.errors.address}
                      </span>
                    ) : null}
                  </div>

                  <div className="input-item mb-5 input-flex input-height">
                    <InputCom
                      placeholder="Pincode "
                      label="Pincode*"
                      type="text"
                      id="pincode"
                      name="pincode"
                      onChange={addressSubmit.handleChange}
                      onBlur={addressSubmit.handleBlur}
                      value={addressSubmit.values.pincode}
                      className={`input-height input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none input-height`}
                    />
                    {addressSubmit.touched.pincode &&
                      addressSubmit.errors.pincode ? (
                      <span className="errorMsg mt-6">
                        {addressSubmit.errors.pincode}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="signin-area mt-5">
              <div className="flex justify-center">
                <button
                  type="submit"
                  disabled={isAddressLoading}
                  className="yellow-btn"
                >
                  <div className="w-full text-sm font-semibold mb-4">
                    {isAddressLoading ? "Adding..." : "Add New Address"}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
