import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css"; //core css
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Routers from "./Routers";

function App() {
  const location = useLocation();
  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname === "/home-two") {
      document.body.classList.add("home-two");
    } else if (location.pathname === "/home-four") {
      document.body.classList.add("home-four");
    } else if (location.pathname === "/home-five") {
      document.body.classList.add("home-five");
    } else if (location.pathname === "/") {
      //document.body.classList.remove("home-two");
      //document.body.classList.add("home-one");
    } else {
      document.body.classList.add("home-one");
    }

    return () => {
      document.body.classList.remove("home-two");
      document.body.classList.remove("home-four");
      document.body.classList.remove("home-five");
      document.body.classList.add("home-one");
    };
  }, [location.pathname]);
  return (
    <>
      <Routers />
      <div className=" whatsapp-logo">
        <a
          href="https://wa.me/9318350112"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/assets/images/logo/WhatsApp_icon.png"
            // className="whatsapp-logo"
            alt="WhatsApp Logo"
          />
        </a>
        <p>Raise <hr/> Enquiry</p>
      </div>
    </>
  );
}

export default App;
