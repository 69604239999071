// For Add Item to Cart
export const addCart = (product) => {
  return {
    type: "ADDITEM",
    payload: product,
  };
};

// For Delete Item to Cart
export const delCart = (product) => {
  return {
    type: "DELITEM",
    payload: product,
  };
};

// For Delete Item to Cart
export const delCartRow = (product) => {
  return {
    type: "DELITEMROW",
    payload: product,
  };
};

export const resetCart = () => {
  return {
    type: "RESETCART",
  };
};
