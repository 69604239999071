import axios from "axios";
import { useEffect, useState } from "react";
import "react-input-range/lib/css/index.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Constants from "../../Constants/index";
import BreadcrumbCom from "../BreadcrumbCom";
import ProductCardStyleOne from "../Helpers/Cards/ProductCardStyleOne";
import DataIteration from "../Helpers/DataIteration";
import Layout from "../Partials/Layout";
import ProductsFilter from "./ProductsFilter";

export default function AllProductPage() {
  const { catId } = useParams();

  const topSearchProductValue = useSelector((state) => state.topSearchProduct);

  //console.log("topSearchProductValue=>>:", topSearchProductValue);

  const [filters, setFilter] = useState({
    mobileLaptop: false,
    gaming: false,
    imageVideo: false,
    vehicles: false,
    furnitures: false,
    sport: false,
    foodDrinks: false,
    fashion: false,
    toilet: false,
    makeupCorner: false,
    babyItem: false,
    apple: false,
    samsung: false,
    walton: false,
    oneplus: false,
    vivo: false,
    oppo: false,
    xiomi: false,
    others: false,
    sizeS: false,
    sizeM: false,
    sizeL: false,
    sizeXL: false,
    sizeXXL: false,
    sizeFit: false,
  });

  const checkboxHandler = (e) => {
    const { name } = e.target;
    setFilter((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };
  const [volume, setVolume] = useState({ min: 200, max: 500 });

  const [storage, setStorage] = useState(null);
  const filterStorage = (value) => {
    setStorage(value);
  };
  const [filterToggle, setToggle] = useState(false);

  //const { products } = productDatas;

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState(
    catId ? [parseInt(catId)] : []
  );

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const resp = await axios.get(Constants.api + "products");
        if (resp.data.success) {
          setProducts(resp.data.products);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const resp = await axios.get(Constants.api + "cetegories");
        if (resp.data.success) {
          setCategories(resp.data.categories);
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchCategories();
  }, []);

  const handleCheckboxChange = (categoryId) => {
    if (selectedCategories.includes(categoryId)) {
      setSelectedCategories(
        selectedCategories.filter((id) => id !== categoryId)
      );
    } else {
      setSelectedCategories([...selectedCategories, categoryId]);
    }

    console.log(selectedCategories);
  };

  // const filteredProducts = products.filter((product) => {
  //   if (selectedCategories.length === 0) {
  //     return true; // No categories selected, show all products
  //   }
  //   return selectedCategories.includes(product.category_id);
  // });

  const filteredProducts = products.filter((product) => {
    const isSearchNotEmpty =
      typeof topSearchProductValue === "string" &&
      topSearchProductValue.trim() !== "";
    const isCategoriesEmpty = selectedCategories.length === 0;

    if (isSearchNotEmpty) {
      if (isCategoriesEmpty) {
        // No categories selected, filter by search value only
        return product.title
          .toLowerCase()
          .includes(topSearchProductValue.toLowerCase());
      } else {
        // Categories selected, filter by categories and search value
        return (
          selectedCategories.includes(product.category_id) &&
          product.title
            .toLowerCase()
            .includes(topSearchProductValue.toLowerCase())
        );
      }
    }

    // If search is blank, filter based on categories only
    if (isCategoriesEmpty) {
      return true; // Show all products when no search and no categories
    }

    return selectedCategories.includes(product.category_id); // Filter by selected categories
  });

  // console.log("filteredProducts >>>>>>>>>>>>");
  // console.log(filteredProducts);

  return (
    <>
      <Layout>
        <div className="products-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom />
            <div className="w-full lg:flex lg:space-x-[30px]">
              <div className="lg:w-[270px]">
                {/* <div
                  className={`filter-widget w-full fixed lg:relative left-0 top-0 h-screen z-10 lg:h-auto overflow-y-scroll lg:overflow-y-auto bg-white px-[30px] pt-[40px]`}
                >
                  <div className="filter-subject-item pb-10 border-b border-qgray-border">
                    <div className="subject-title mb-[30px]">
                      <h1 className="text-black text-base font-500">
                        Product categories
                      </h1>
                    </div>
                    <div className="filter-items">
                      <ul>
                        {categories.map((category) => (
                          <li
                            className="item flex justify-between items-center mb-5"
                            key={category.id}
                          >
                            <div className="flex space-x-[14px] items-center">
                              <div>
                                <input
                                  type="checkbox"
                                  id={`category-${category.id}`}
                                  value={category.id}
                                  checked={
                                    selectedCategories.includes(category.id) ||
                                    category.id === parseInt(catId)
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(category.id)
                                  }
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor={`category-${category.id}`}
                                  className="text-xs font-black font-400 capitalize"
                                >
                                  {category.title}
                                  {catId}
                                </label>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div> */}

                <ProductsFilter
                  filterToggle={filterToggle}
                  filterToggleHandler={() => setToggle(!filterToggle)}
                  filters={filters}
                  checkboxHandler={checkboxHandler}
                  volume={volume}
                  volumeHandler={(value) => setVolume(value)}
                  storage={storage}
                  filterstorage={filterStorage}
                  categories={categories}
                  selectedCategories={selectedCategories}
                  handleCheckboxChange={handleCheckboxChange}
                  catId={catId}
                  className="mb-[30px]"
                />
                {/* <div className="w-full hidden lg:block h-[295px]">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/ads-5.png`}
                    alt=""
                    className="w-full h-full object-contain"
                  />
                </div> */}
              </div>

              <div className="flex-1">
                <div className="products-sorting w-full bg-white md:h-[70px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[30px] mb-[40px]">
                  {/* <div>
                    <p className="font-400 text-[13px]">
                      <span className="text-qgray"> Showing</span> 1–16 of 66
                      results
                    </p>
                  </div>
                  <div className="flex space-x-3 items-center">
                    <span className="font-400 text-[13px]">Sort by:</span>
                    <div className="flex space-x-3 items-center border-b border-b-qgray">
                      <span className="font-400 text-[13px] text-qgray">
                        Default
                      </span>
                      <span>
                        <svg
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1 1L5 5L9 1" stroke="#9A9A9A" />
                        </svg>
                      </span>
                    </div>
                  </div> */}
                  <button
                    onClick={() => setToggle(!filterToggle)}
                    type="button"
                    className="w-10 lg:hidden h-10 rounded flex justify-center items-center border border-qyellow text-qyellow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                      />
                    </svg>
                  </button>
                </div>
                <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1  xl:gap-[30px] gap-5 mb-[40px]">
                  <DataIteration
                    datas={filteredProducts}
                    startLength={0}
                    endLength={filteredProducts.length}
                  >
                    {({ datas }) => (
                      <div data-aos="fade-up" key={datas.id}>
                        <ProductCardStyleOne datas={datas} />
                      </div>
                    )}
                  </DataIteration>
                </div>

                {/* <div className="w-full h-[164px] overflow-hidden mb-[40px]">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/ads-6.png`}
                    alt=""
                    className="w-full h-full object-contain"
                  />
                </div>
                <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-5 mb-[40px]">
                  <DataIteration
                    datas={products}
                    startLength={6}
                    endLength={products.length}
                  >
                    {({ datas }) => (
                      <div data-aos="fade-up" key={datas.id}>
                        <ProductCardStyleOne datas={datas} />
                      </div>
                    )}
                  </DataIteration>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
